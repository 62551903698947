.checkin-process {
    display: flex;
    flex-direction: row;

    align-items: top;
    min-height: 100vh;

    @media screen and (max-width: 1400px) {
        flex-direction: column;
    }

    .checkin-recap, .checkin-form {
        flex: 1 1 0px;
    }

    .checkin-recap {
        padding-top: 110px;
        padding-right: 110px;

        @media screen and (max-width: 1400px) {
            display: block;
            padding-top: 20px;
            padding-right: 0;
        }
    }

    .checkin-form {
        background: @grey-3;
        padding: 110px;

        @media screen and (max-width: 600px) {
            padding: 20px;
        }

        .wrapper {
            max-width: 380px;
            
            @media screen and (max-width: 1400px) {
                margin: auto;
                max-width: 70%;
            }


            @media screen and (max-width: 850px) {
                max-width: none;
                width: 100%;
            }


            > .input-element, .transparent > .input-element, .input-row {
                margin-bottom: 14px;
            }

            .submit-row {
                margin-top: 45px;
            }
        }
    }
}