.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 300;
    background-color: rgba(0,0,0,0.8);
}

.ReactModal__Content {
    position: absolute;
    width: 90%;

    max-width: 540px;

    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    background: white;
    border-radius: 4px;
    padding: 40px 20px;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.tutorial-modal {
    text-align: center;

    h1 {
        display: inline-block;
        text-align: center;

        font-size: 20px;
    }

    h1, li {
        img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }

    ul {
        text-align: left;
        margin: 30px 0;

        li {
            margin: 10px 0;
            font-size: 14px;
        }
    }
}