@import "./reset.less";
@import "./fonts.less";

@import "./components/recap.less";
@import "./components/inputs.less";
@import "./components/separator.less";
@import "./components/button.less";
@import "./components/modal.less";
@import "./components/loader.less";

@import "./pages/form.less";
@import "./pages/confirm.less";
@import "./pages/error.less";

@violet: #3c32f8;
@grey-1: #30325d;
@grey-2: #445871;
@grey-3: rgba(213, 220, 231, 0.1);
@grey-4: #D5DCE7;
@red: #EF003B;


@bold: 700;
@semibold:  600;
@normal: 400;

body {
  background: white;
  margin: 0;

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
  }
}
