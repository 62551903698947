.checkin-confirm {
  max-width: 600px;
  width: 90%;
  margin: auto;

  padding: 95px 0;

  h1 {
    font-size: 24px;
    font-weight: @bold;

    margin-bottom: 30px;

    img {
      display: inline-block;
      vertical-align: middle;
      margin-left: -68px;
      margin-right: 20px;
      width: 48px;
      height: 48px;
    }

    @media screen and (max-width: 800px) {
      text-align: center;
      img {
        display: block;
        margin: auto;
        margin-bottom: 20px;
      }
    }
  }

  .copy {
    ul {
      padding-left: 20px;
      margin: 25px 0;

      li {
        list-style-type: disc;

        + li {
          margin-top: 15px;
        }
      }
    }
  }

  .checkin-recap {
    margin: 25px 0;

    .wrapper {
      width: 100%;

      .departure {
        padding-right: 0;
        padding-left: 25px;
        border-left: 2px solid @grey-4;

        @media screen and (max-width: 800px) {
          border-left: none;
          padding-left: 0;
        }
      }

      .arrival {
        padding-left: 25px;

        @media screen and (max-width: 800px) {
          padding-left: 0;
        }
      }
    }
  }

  footer {
      font-weight: @semibold;
      margin-top: 20px;
  }
}
