.loader {
    display: block;
    max-width: 450px;

    margin: auto;

    position: absolute;
    top: 30%;
    left: 50%;

    text-align: center;

    transform: translateY(-50%) translateX(-50%);


    .loader-svg {
        position: relative;
        margin-top: 50px;
        animation: loadingCar infinite linear 1.5s;
    }

    @keyframes loadingCar {
        0% {
            left: -10rem;
            opacity: 0;
        }
        10%, 90% {
            opacity: 1;
        }
        20%, 60% {
            transform: scale(1,1.05);
        }
        40%, 80% {
            transform: scale(1,.95);
        }
        100% {
            left: 10rem;
            opacity: 0;
        }
    }
}