.separator {
    border-bottom: 1px solid @grey-4;

    &.separated-top {
        margin-top: 30px;
    }

    &.separated-bottom {
        margin-bottom: 15px; //Deal with it ;)
    }

    strong {
        display: block;
        color: @grey-4;
        text-transform: uppercase;
        font-weight: @bold;
        font-size: 14px;

        margin-bottom: 10px;
    }
}