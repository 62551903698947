.button {
    display: inline-block;
    font-weight: @bold;
    font-size: 14px;

    padding: 10px 20px;
    border-radius: 4px;

    cursor: pointer;
    user-select: none;

    &.button-main {
        color: white;
        background: @violet;

        &:hover {
            background: lighten(@violet, 10%);
        }
    }

    &.button-secondary {
        background: @grey-4;
        color: @grey-1;

        &:hover {
            background: lighten(@grey-4, 4%);
        }
    }
}