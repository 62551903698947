.checkin-error {
    max-width: 600px;
    width: 90%;
    margin: auto;
  
    padding: 95px 0;
  
    h1 {
      font-size: 24px;
      font-weight: @bold;
  
      margin-bottom: 30px;
  
      @media screen and (max-width: 800px) {
        text-align: center;
        img {
          display: block;
          margin: auto;
          margin-bottom: 20px;
        }
      }
    }
  
    footer {
        font-weight: @semibold;
        margin-top: 20px;
    }
  }
  