.input-element {
  .label {
    display: block;
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 16px;

    color: @grey-1;
    font-weight: @semibold;
  }

  input::placeholder, .react-select__placeholder {
    color: @grey-4;
  }

  &.text,
  &.phone {
    input {
      border: none;
      background: none;
      width: 100%;
      padding: 10px 16px;

      color: @grey-1;
      font-size: 14px;
      font-weight: @normal;

      outline: none;
    }

    .input-wrapper {
      position: relative;

      background: white;
      border-radius: 4px;
      border: 1px solid @grey-4;

      &:focus-within {
        border-color: @grey-1;
        outline: none;
      }

      .checkmark-icon {
        position: absolute;
        right: 0;
        top: 50%;

        transform: translate3d(-50%, -50%, 0);
      }
    }
  }

  &.phone {
    .intl-tel-input {
      width: 100%;

      .flag-container {
        .selected-flag {
          padding-left: 10px;
          padding-right: 5px;
          width: 65px;
        }
      }

      .country-list {
        user-select: none;

        margin-top: 10px;
        border: 1px solid @grey-4;
        border-radius: 4px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

        color: @grey-1;
        font-size: 14px;

        .country {
          padding: 6px 15px;
        }

        .country.highlight {
          background: @grey-1;
          color: white;
        }
      }

      //Ugly ass arrow
      .flag-container .arrow.down:after,
      .flag-container .arrow.up:after {
        content: " ";
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: 5px;

        width: 9px;
        height: 14px;

        transition: transform ease 0.2s;

        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOSA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC44NDc2NTYgMEMwLjM1NTQ2OSAwIDAuMTA5Mzc1IDAuNjAxNTYyIDAuNDY0ODQ0IDAuOTU3MDMxTDMuOTY0ODQgNC40NTcwM0M0LjE4MzU5IDQuNjc1NzggNC41MzkwNiA0LjY3NTc4IDQuNzU3ODEgNC40NTcwM0w4LjI1NzgxIDAuOTU3MDMxQzguNjEzMjggMC42MDE1NjIgOC4zNjcxOSAwIDcuODc1IDBIMC44NDc2NTZaIiBmaWxsPSIjMzAzMjVEIi8+Cjwvc3ZnPgo=");
      }

      .flag-container .arrow.up:after {
        transform: rotate(180deg);
      }

      input[type="tel"] {
        padding-left: 70px;
      }
    }
  }

  &.address {
    .react-select__indicators {
      display: none;
    }

    .react-select__loading-indicators {
      display: block !important;
    }

    .react-select__input > input {
      opacity: 1 !important;
    }
  }

  &.country,
  &.address {
    .react-select__control {
      border-color: @grey-4;
      color: @grey-1;
      font-size: 14px;
      font-weight: @normal;

      &--is-focused {
        box-shadow: none;
        border: 1px solid @grey-1;
      }
    }

    .react-select__value-container {
      padding: 5px 14px;
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__menu {
      margin-top: 10px;
      border: 1px solid @grey-4;
      border-radius: 4px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

      color: @grey-1;

      .react-select__option {
        font-size: 14px;
      }

      .react-select__option--is-focused {
        background-color: @grey-1;
        color: white;
      }

      .react-select__option--is-selected {
        background-color: lighten(@grey-1, 15%);
        color: white;
      }
    }

    .react-select__indicator {
      svg {
        display: none;
      }

      &:after {
        content: " ";
        display: block;
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: 5px;

        width: 9px;
        height: 14px;

        transition: transform ease 0.2s;

        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOSA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC44NDc2NTYgMEMwLjM1NTQ2OSAwIDAuMTA5Mzc1IDAuNjAxNTYyIDAuNDY0ODQ0IDAuOTU3MDMxTDMuOTY0ODQgNC40NTcwM0M0LjE4MzU5IDQuNjc1NzggNC41MzkwNiA0LjY3NTc4IDQuNzU3ODEgNC40NTcwM0w4LjI1NzgxIDAuOTU3MDMxQzguNjEzMjggMC42MDE1NjIgOC4zNjcxOSAwIDcuODc1IDBIMC44NDc2NTZaIiBmaWxsPSIjMzAzMjVEIi8+Cjwvc3ZnPgo=");
      }
    }
  }

  &.disabled {
    .input-wrapper {
      background: @grey-3;
    }
  }

  &.checkbox {
    input {
      display: none;

      &:checked {
        + label:before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjg5NDUgMS4wNDY4OEw0LjcwMzEyIDguMjM4MjhMMi4wNzgxMiA1LjU4NTk0QzEuOTQxNDEgNS40NzY1NiAxLjcyMjY2IDUuNDc2NTYgMS42MTMyOCA1LjU4NTk0TDAuODIwMzEyIDYuMzc4OTFDMC43MTA5MzggNi40ODgyOCAwLjcxMDkzOCA2LjcwNzAzIDAuODIwMzEyIDYuODQzNzVMNC40ODQzOCAxMC40ODA1QzQuNjIxMDkgMTAuNjE3MiA0LjgxMjUgMTAuNjE3MiA0Ljk0OTIyIDEwLjQ4MDVMMTMuMTUyMyAyLjI3NzM0QzEzLjI2MTcgMi4xNjc5NyAxMy4yNjE3IDEuOTQ5MjIgMTMuMTUyMyAxLjgxMjVMMTIuMzU5NCAxLjA0Njg4QzEyLjI1IDAuOTEwMTU2IDEyLjAzMTIgMC45MTAxNTYgMTEuODk0NSAxLjA0Njg4WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
          background-repeat: no-repeat;
          background-position: center center;
          background-color: @grey-1;
        }
      }
    }

    label {
      display: block;
      font-size: 12px;
      font-weight: @semibold;

      user-select: none;
      cursor: pointer;

      margin: 20px 0;

      &:before {
        position: relative;

        content: " ";
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;

        width: 24px;
        height: 24px;
        border-radius: 4px;
        background: white;
        border: 1px solid @grey-1;

        transition: background-color ease 0.2s;
      }
    }

    &.disabled {
      pointer-events: none;

      .input-wrapper {
        background: none;
      }

      label {
        opacity: 0.6;
      }

      label:before {
        background: @grey-3;
      }
    }
  }

  &.gender {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .label {
      margin-bottom: 0;
    }

    .input-error {
      flex-basis: 100%;
    }

    >* {
      width: auto;
    }
    
    .input-area {
      padding-left: 30px;
      display: flex;
      
      .input-wrapper + .input-wrapper {
        margin-left: -8px;
      }

      .input-wrapper {
        &:focus, &:focus-within {
          outline: none;
        }
      }

      label {
        display: block;

        background: white;
        color: @grey-2;
        z-index: 1;
        position: relative;

        font-weight: @semibold;
        font-size: 14px;
        border-radius: 100px;
        padding: 4px 15px;
        min-width: 70px;
        text-align: center;

        cursor: pointer;
        user-select: none;

        transition: all ease 0.2s;
      }

      input[type="radio"] {
        display: none;

        &:checked {
          + label {
            z-index: 2;
            background: @violet;
            color: white;
          }
        }
      }
    }
  }
}

.input-error {
  display: block;
  font-weight: @semibold;
  font-size: 12px;
  color: @red;

  margin-top: 6px;

  &:before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 12px;

    margin-right: 5px;

    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjMzNTkgMTAuODM1OUw4LjcxMDk0IDEuMDYyNUM4LjI4OTA2IDAuMzM1OTM4IDcuMTg3NSAwLjMxMjUgNi43NjU2MiAxLjA2MjVMMS4xNDA2MiAxMC44MzU5QzAuNzE4NzUgMTEuNTYyNSAxLjI1NzgxIDEyLjUgMi4xMjUgMTIuNUgxMy4zNTE2QzE0LjIxODggMTIuNSAxNC43NTc4IDExLjU4NTkgMTQuMzM1OSAxMC44MzU5Wk03Ljc1IDguNzk2ODhDOC4zMzU5NCA4Ljc5Njg4IDguODI4MTIgOS4yODkwNiA4LjgyODEyIDkuODc1QzguODI4MTIgMTAuNDg0NCA4LjMzNTk0IDEwLjk1MzEgNy43NSAxMC45NTMxQzcuMTQwNjIgMTAuOTUzMSA2LjY3MTg4IDEwLjQ4NDQgNi42NzE4OCA5Ljg3NUM2LjY3MTg4IDkuMjg5MDYgNy4xNDA2MiA4Ljc5Njg4IDcuNzUgOC43OTY4OFpNNi43MTg3NSA0LjkyOTY5QzYuNjk1MzEgNC43NjU2MiA2LjgzNTk0IDQuNjI1IDcgNC42MjVIOC40NzY1NkM4LjY0MDYyIDQuNjI1IDguNzgxMjUgNC43NjU2MiA4Ljc1NzgxIDQuOTI5NjlMOC41OTM3NSA4LjExNzE5QzguNTcwMzEgOC4yODEyNSA4LjQ1MzEyIDguMzc1IDguMzEyNSA4LjM3NUg3LjE2NDA2QzcuMDIzNDQgOC4zNzUgNi45MDYyNSA4LjI4MTI1IDYuODgyODEgOC4xMTcxOUw2LjcxODc1IDQuOTI5NjlaIiBmaWxsPSIjRUYwMDNCIi8+Cjwvc3ZnPgo=");
  }
}

&.file {
  font-size: 12px;

  .file-zone {
    background: white;
    border: 1px dashed @grey-4;
    border-radius: 4px;

    padding: 30px 70px;
    text-align: center;

    &.active {
      border: 1px solid @grey-4;
    }

    img {
      margin: auto;
    }

    a {
      cursor: pointer;
      font-weight: @bold;
      margin-top: 10px;
    }

    strong,
    a {
      display: block;
    }

    .input-wrapper {
      &.active {
        color: @violet;
      }
    }

    .input-area {
      input[type="file"] {
        display: none;
      }
    }

    .button {
      margin-top: 20px;
      font-size: 12px;
      border-radius: 2px;
    }
  }
}

&.width-20 {
  width: calc(20% - 10px);
}

&.width-50 {
  width: calc(50% - 10px);
}

.input-row {
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  > * {
    flex-grow: 1;

    + * {
      margin-left: 20px;
    }
  }

  > .button {
    flex-grow: 0;
  }
}
