.checkin-recap {
  display: flex;
  justify-content: flex-end;

  .wrapper {
    width: 70%;

    @media screen and (max-width: 1400px) {
      width: 80%;
      margin: auto;

      margin-bottom: 40px;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .sticky-inner-wrapper > * {
      + * {
        margin-top: 40px;
      }
    }
  }

  .recap-copy {
    color: @grey-1;

    p + p {
      margin-top: 15px;
    }

    @media screen and (max-width: 1300px) {
      font-size: 14px;
    }
  }

  .recap-infos {
    display: flex;
    flex-direction: row;
    width: 100%;

    @media screen and (max-width: 800px) {
      display: block;
    }

    > div {
      display: flex;
      flex-direction: column;
      flex: 1 1 0px;

      strong.info-line {
        .icon {
          //Shitty icon set :(
          width: 9px;
          margin-right: 15px;
        }
      }

      span {
        font-size: 12px;
        font-weight: @semibold;
        color: @grey-1;
      }

      .info-line {
        display: flex;
        align-items: center;
        font-weight: @normal;
        font-size: 14px;

        .icon {
          display: inline-block;
          vertical-align: middle;

          height: 12px;
          width: 12px;

          margin-right: 12px;
        }

        &.address {
          .name {
            font-weight: @bold;
            color: @violet;
            font-size: 16px;
          }

          span {
            display: block;
          }
        }
      }

      &.departure {
        padding-right: 40px;
      }

      &.arrival {
        border-left: 2px solid rgba(0, 0, 0, 0.1);
        padding-left: 40px;

        @media screen and (max-width: 800px) {
          padding-left: 0;
          border-left: none;

          &:before {
            content: " ";
            display: block;

            margin: 15px 0;

            height: 2px;
            width: 30px;
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    &.withIcons {
      .type {
        margin-bottom: 10px;
        color: @grey-2;
      }

      .info-line + .info-line {
        margin-top: 10px;
      }
    }
  }

  .recap-car {
    display: flex;
    flex-direction: row;
    align-items: center;

    > * {
      flex: 1 1 0px;
      width: 50%;
    }

    > img {
      padding-right: 20px;
    }

    .car-details {
      padding-left: 20px;
      color: @grey-1;

      strong {
        display: block;
        font-weight: @normal;
      }
    }

    @media screen and (max-width: 800px) {
      > img {
        flex-grow: 0;
        padding-right: 0;
        max-height: 80px;
        width: auto;
      }
    }
  }
}
